import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyAction, combineReducers } from 'redux';

import type { SearchModel } from '../@types/SearchModel';
import modal, { ModalSliceState } from './modalSlice';
import search, { SearchSliceState } from './searchSlice';

export interface BroadbandSliceState {
    search: SearchSliceState;
    modal: ModalSliceState;
}

export const mapSearchModelToState = (searchModel: SearchModel) => {
    const state: SearchSliceState = {
        criteria: searchModel.state.criteria,
        settings: {
            coverageUrl: searchModel.coverageUrl,
            searchUrl: searchModel.searchUrl,
            resultsSearchCriteria: searchModel.state.criteria,
            pagedResultsUrl: searchModel.pagedResultsUrl,
            suppressCoverageCheck: searchModel.suppressCoverageCheck,
            embeddedMode: searchModel.embeddedMode,
            enforceAjax: searchModel.embeddedMode,
            allConnectionsSelected: searchModel.state.allConnectionsSelected,
            coverage: searchModel.state.coverage,
            hideSupplierFilter: searchModel.state.hideSupplierFilter,
            suppliers: searchModel.state.suppliers,
            unavailableConnectionTypes: searchModel.state.unavailableConnectionTypes,
            defaultSearchCriteria: searchModel.defaultSearchCriteria,
            defaultPrepaidExpiryDays: searchModel.defaultPrepaidExpiryDays
        }
    };

    if (searchModel.filters) {
        state.settings.allContractTerms = searchModel.filters.allContractTerms;
        state.settings.defaultSuppliers = searchModel.filters.defaultSuppliers;
    }

    return state;
};

const broadbandSlice = createSlice({
    name: 'broadband',
    initialState: {} as BroadbandSliceState,
    reducers: {
        init(state, action: PayloadAction<SearchModel>) {
            state.search = mapSearchModelToState(action.payload);
        }
    }
});

const features = combineReducers<BroadbandSliceState>({
    search,
    modal
});

export default function broadbandReducer(state: BroadbandSliceState, action: AnyAction) {
    const featuresState = features(state, action);
    return broadbandSlice.reducer(featuresState, action);
}

export const actions = broadbandSlice.actions;
